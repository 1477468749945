import React, { useState, useEffect } from "react";
import { navigate, Link } from "gatsby"
import styled from "styled-components";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import { useForm } from "react-hook-form";
import axios from "axios";




import HvezdaImg from "../assets/img/hvezdaImg.svg";
import FirmyImg from "../assets/img/firmyczLogo.png";
import MapsImg from "../assets/img/mapsLogo.png";
import DetiImg from "../assets/img/zivotDetemLogo.png";
import StatistkyPDF from "../assets/statistiky-goodbye.pdf";

const TrendyStatstikyInner = styled.div`
  max-width: 1150px;
  display: block;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  width: calc(100% - 50px);

  h1 {
    font-family: NewKansas;
    font-size: 33px;
  }

  p {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: #484848;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 25px;

    a {
      color: #484848;
    }
  }

  .buttony {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    width: calc(100%);
    max-width: 290px;
    margin: 0;
    display: block;
    background: #1D3A5B;
    border-radius: 3px;
    padding: 19px 0;
    text-decoration: none;
    margin-bottom: 15px;

    @media (max-width: 900px) {
      padding: 18px 0;
      max-width: 260px;
    }

    &:hover {
      background: #081C33;
      cursor: pointer;
    }
  }

  span {
    font-family: Visuelt-Regular;
    font-size: 16px;
    color: gray;
    display: block;
    margin-bottom: 30px;
  }
`;



const TrendyStatstiky = () => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });


  return (
    <Layout V2>
      <SEO
        title="Zajímavé trendy a statistiky o smrti - Goodbye.cz"
        customTitle
        description="Jaké jsou pohřebnické trendy v České republice? Vytvořili jsme přehled nejzajímavějších faktů, která vycházejí z našich interních dat podložených našimi zákazníky a jejich příběhy."
      />

      <TrendyStatstikyInner>
        <h1>Zajímavé trendy a statistiky o smrti očima moderní pohřební služby</h1>
        <p>Jaké jsou pohřebnické trendy v České republice? Co stojí za vzrůstajícím zájmem o pohřby bez obřadu či ekologické urny?<br/><br/>Po prvním roce působení naší moderní <Link to="/pohrebni-sluzba/">pohřební služby</Link> jsme vytvořili přehled nejzajímavějších faktů, která vycházejí z našich interních dat podložených našimi zákazníky a jejich příběhy.</p>

        <a className="buttony" href="/statistiky-goodbye.pdf" download>Stáhnout celý report</a>
        <Link className="buttony" to="https://bit.ly/3ecnK1v">Náš press kit</Link>

        <span>Nezapomeňte nás prosím citovat :-)</span>

        <p>Kontakt:<br/><br/>Jiří Štěpánek, zakladatel<br/>stepanek@goodbye.cz<br/>605 176 305</p>

      </TrendyStatstikyInner>
    </Layout>
  )
}



export const query = graphql`
  query {

    big: file(relativePath: { eq: "pohrebPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "pohrebPozadiSmall.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default TrendyStatstiky